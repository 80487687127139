<template>
  <div class="wrapper">
      <v-expand-transition>
        <router-view />
      </v-expand-transition>
  </div>
</template>
<script>

export default {
  name: "Statistics",
  components: {
  },
  data() {
    return {
      parents: [],
    };
  },
  methods: {
    
  },
  computed: {
    
  },
  // destroyed() {
  //     this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
  //     this.$store.dispatch('base/SET_SUB_MENU', [])
  // },
  beforeRouteLeave (to, from, next) {
      this.$store.dispatch('base/SET_SUB_MENU', []);
      this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
      next();
  },
  mounted() {
    
  },
};
</script>
